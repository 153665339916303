<template>
	<div>
		<ZyroFieldText
			:id="`${currentElementId}-text`"
			v-model="source"
			:label="$t('builder.editMap.tabActionLabel')"
			@blur="commitToHistory"
		/>
	</div>
</template>

<script>
import {
	mapState,
	mapGetters,
	mapMutations,
} from 'vuex';

import {
	addressToGoogleMapUrl,
	googleMapUrlToAddress,
} from '@/utils/urlValidators';

export default {
	data() {
		return {
			currentUrl: '',
			inputValue: '',
			currentId: '',
		};
	},
	computed: {
		...mapState([
			'currentElementId',
			'currentElementRef',
		]),
		...mapGetters(['currentElement']),
		source: {
			set(event) {
				this.inputValue = event.target.value;
				this.currentUrl = addressToGoogleMapUrl(this.inputValue);
				this.currentElementRef.$el.src = this.currentUrl;
			},
			get() {
				return this.inputValue;
			},
		},
	},
	mounted() {
		const sourceValue = this.currentElement.settings.src;

		this.currentId = this.currentElementId;
		this.inputValue = googleMapUrlToAddress(sourceValue);
		this.currentUrl = sourceValue;
	},
	beforeDestroy() {
		this.commitToHistory();
	},
	methods: {
		...mapMutations(['setElementData']),
		commitToHistory() {
			this.setElementData({
				elementId: this.currentId,
				data: { settings: { src: this.currentUrl } },
				skipHistory: false,
			});
		},
	},
};
</script>
