<template>
	<div>
		<ZyroCssShorthandControl
			units="px"
			:value.sync="padding"
			:label="$t('common.padding')"
			:title-vertical="$t('common.vertical')"
			:title-horizontal="$t('common.horizontal')"
		/>
	</div>
</template>

<script>
import {
	mapState,
	mapGetters,
	mapMutations,
} from 'vuex';

import { cloneDeep } from '@/utils/object';

export default {
	data() {
		return {
			elementId: '',
			currentElementBeforeEdit: null,
		};
	},
	computed: {
		...mapState(['currentElementId']),
		...mapGetters(['currentElement']),
		padding: {
			get() {
				return this.currentElement.settings.styles['grid-item-padding'] || '0';
			},
			set(newValue) {
				this.setElementData({ data: { settings: { styles: { 'grid-item-padding': newValue } } } });
			},
		},
	},
	mounted() {
		this.elementId = this.currentElementId;
		this.currentElementBeforeEdit = cloneDeep(this.currentElement);
	},
	beforeDestroy() {
		this.pushElementDataToHistory({
			elementId: this.elementId,
			oldData: this.currentElementBeforeEdit,
		});
	},
	methods: mapMutations([
		'setElementData',
		'pushElementDataToHistory',
	]),
};
</script>
