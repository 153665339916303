import { render, staticRenderFns } from "./BaseEditControls.vue?vue&type=template&id=be83f586&scoped=true&"
import script from "./BaseEditControls.vue?vue&type=script&lang=js&"
export * from "./BaseEditControls.vue?vue&type=script&lang=js&"
import style0 from "./BaseEditControls.vue?vue&type=style&index=0&id=be83f586&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "be83f586",
  null
  
)

export default component.exports