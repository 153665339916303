<template>
	<div class="edit-actions">
		<Transition
			appear
			name="popup"
		>
			<div
				v-if="!isEditMode"
				ref="defaultControl"
				key="top-controls"
				class="edit-actions__top"
				:style="defaultControlPosition"
			>
				<ZyroButton
					v-if="buttonTitle"
					class="edit-actions__button"
					theme="editor"
					data-qa="builder-textelementedit-buttonedittext"
					@click="executeElementAction('edit')"
				>
					{{ buttonTitle }}
				</ZyroButton>
				<!-- When using with buttons dont forget to set the elements block as current -->
				<slot name="additional-buttons" />
				<ZyroPopup
					v-if="isMobileView && showAlignSelfPopup"
					class="edit-actions__align"
					:show-close-button="false"
					:offset="{ top: 40 }"
					type="no-padding"
				>
					<template slot="trigger">
						<ZyroButton
							title="align-items"
							:icon="iconName"
							theme="editor"
							class="edit-actions__align-button"
						/>
					</template>
					<ZyroIconControls
						class="edit-actions__align-controls"
						:current.sync="alignSelf"
						:icons="$options.valuesAlignSelf"
					/>
				</ZyroPopup>
				<ZyroButton
					v-if="showDuplicate"
					class="edit-actions__button"
					theme="editor"
					icon="duplicate"
					data-qa="builder-textelementedit-buttonduplicate"
					:title="$t('common.duplicate')"
					@click="executeElementAction('duplicate')"
				/>
				<ZyroButton
					v-if="showDelete"
					class="edit-actions__button"
					theme="editor"
					icon="trash"
					data-qa="builder-textelementedit-buttondelete"
					:title="$t('common.delete')"
					@click="executeElementAction('remove')"
				/>
			</div>
			<div
				v-else
				ref="editControl"
				key="bottom-controls"
				class="edit-actions__bottom"
				:style="editControlPosition"
			>
				<slot />
			</div>
		</Transition>
	</div>
</template>

<script>
import {
	mapActions,
	mapGetters,
	mapState,
	mapMutations,
} from 'vuex';

import {
	moveRight,
	moveLeft,
} from '@/utils/array';
import position from '@/utils/positioning';

const valuesAlignSelf = [
	{
		value: 'flex-start',
		icon: 'align-left-h',
	},
	{
		value: 'center',
		icon: 'align-middle-h',
	},
	{
		value: 'flex-end',
		icon: 'align-right-h',
	},
];

export default {
	valuesAlignSelf,
	props: {
		buttonTitle: {
			type: String,
			default: '',
		},
		blockId: {
			type: String,
			required: true,
		},
		blockRef: {
			validator: (prop) => typeof prop === 'string' || prop instanceof HTMLElement,
			required: true,
		},
		showDuplicate: {
			type: Boolean,
			default: true,
		},
		showDelete: {
			type: Boolean,
			default: true,
		},
		currentTab: {
			type: Object,
			default: () => ({}),
		},
	},
	data() {
		return {
			editControlPosition: {
				top: 0,
				left: 0,
			},
			defaultControlPosition: {
				top: 0,
				left: 0,
			},
		};
	},
	computed: {
		...mapState(['currentElementBoxRef']),
		...mapState('gui', [
			'mobilePreviewRef',
			'desktopPreviewRef',
			'isMobileView',
			'isEditMode',
		]),
		...mapGetters([
			'currentElement',
			'getCurrentElementBlockId',
		]),
		...mapState([
			'website',
			'currentElementId',
		]),
		blockZindexes: {
			get() {
				return this.website.blocks[this.blockId].zindexes;
			},
			set(zindexes) {
				this.setBlockData({
					blockId: this.blockId,
					data: { zindexes },
					skipHistory: false,
				});
			},
		},
		alignSelf: {
			get() {
				return this.currentElement.settings.styles['m-align-self'];
			},
			set(newValue) {
				if (newValue) {
					this.setElementData({
						elementId: this.currentElementId,
						data: { settings: { styles: { 'm-align-self': newValue } } },
					});
				}
			},
		},
		iconName() {
			return valuesAlignSelf.find((item) => item.value === this.alignSelf)?.icon || 'align-left-h';
		},
		showAlignSelfPopup() {
			const excludedElements = [
				'GridButton',
				'GridStripeButton',
			];

			return !excludedElements.includes(this.currentElement.type);
		},
	},
	watch: {
		currentElement() {
			this.computeDefaultControlPosition();
		},
		isEditMode(value) {
			if (value) {
				this.computeEditControlPosition();
			}
		},
		currentTab() {
			this.computeEditControlPosition();
		},
	},
	mounted() {
		window.addEventListener('keydown', this.onKeyDownPressed);
		if (this.isEditMode) {
			this.computeEditControlPosition();
		} else {
			this.computeDefaultControlPosition();
		}
	},
	beforeDestroy() {
		window.removeEventListener('keydown', this.onKeyDownPressed);
		if (window.getSelection) {
			window.getSelection().removeAllRanges();
		} else if (document.selection) {
			document.selection.empty();
		}
	},
	methods: {
		...mapActions([
			'duplicateCurrentElement',
			'removeCurrentElement',
		]),
		...mapActions('gui', ['setEditMode']),
		...mapMutations([
			'setCurrentBlock',
			'setElementData',
			'setBlockData',
		]),
		...mapMutations('gui', ['setRef']),
		onKeyDownPressed(event) {
			// backspace or delete
			if ((event.key === 'Backspace' || event.key === 'Delete') && !this.isEditMode) {
				event.preventDefault();
				this.executeElementAction('remove');
			}

			// ctrl + d or cmd + d
			if ((event.ctrlKey || event.metaKey) && event.keyCode === 68) {
				event.preventDefault();
				this.executeElementAction('duplicate');
			}

			// cmd + backspace or ctrl + backspace
			if (event.metaKey && event.key === 'Backspace') {
				event.preventDefault();
				this.executeElementAction('remove');
			}

			// Push/send to back, 219 = '['
			if (event.metaKey && event.keyCode === 219) {
				event.preventDefault();
				this.blockZindexes = moveLeft(this.blockZindexes, this.currentElementId, event.shiftKey);
			}

			// Push/send to front, 221 = ']'
			if (event.metaKey && event.keyCode === 221) {
				event.preventDefault();
				this.blockZindexes = moveRight(this.blockZindexes, this.currentElementId, event.shiftKey);
			}
		},
		executeElementAction(action) {
			switch (action) {
			case 'remove':
				this.removeCurrentElement();
				break;
			case 'duplicate':
				this.duplicateCurrentElement();
				break;
			case 'edit':
				this.openEdit();
				break;
			default:
				break;
			}
		},
		openEdit() {
			this.setEditMode(true);
		},
		onClose() {
			this.setEditMode(false);
		},
		async computeDefaultControlPosition() {
			// Wait for DOM update (defaultToolbar render), after setting editMode = true
			await this.$nextTick();

			this.defaultControlPosition = position.textEditor(
				this.currentElementBoxRef,
				this.isMobileView ? this.mobilePreviewRef : this.desktopPreviewRef,
				this.$refs.defaultControl || this.$refs.editControl,
			);
		},
		async computeEditControlPosition() {
			await this.$nextTick();
			if (this.currentElement.type === 'GridTextBox') {
				this.editControlPosition = position.textEditor(
					this.currentElementBoxRef,
					this.isMobileView ? this.mobilePreviewRef : this.desktopPreviewRef,
					this.$refs.editControl,
				);
			} else {
				this.editControlPosition = this.isMobileView
					? position.positionElemPopupMobile(
						this.currentElementBoxRef,
						this.mobilePreviewRef,
						this.$refs.editControl,
					)
					: position.positionElemPopupDesktop(
						this.currentElementBoxRef,
						this.desktopPreviewRef,
						this.$refs.editControl,
					);
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.edit-actions {
	position: absolute;
	top: -7px;
	right: $side-toolbar-width;
	width: 100%;

	// Best way to center the popup card. Offset does not fit the situation.
	&__align {
		::v-deep .popup-card {
			transform: translateX(-42px);
		}
	}

	&__align-controls {
		margin: 0;
	}

	&__align-button {
		margin-right: 8px;
	}

	&__button:not(:last-child) {
		margin-right: 8px;
	}

	&__top {
		position: absolute;
		display: flex;
		white-space: nowrap;
	}

	&__bottom {
		position: absolute;
	}

	&::v-deep {
		hr {
			width: 100%;
			height: 1px;
			background-color: $grey-300;
			border: 0;
		}
	}
}
</style>
