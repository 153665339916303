<template>
	<BaseEditControls
		:block-id="$parent.blockId"
		:block-ref="$parent.blockRef"
		:button-title="$t('builder.editMap.title')"
	>
		<ZyroPopupCard
			type="editor"
			:title="$t('builder.editMap.popupTitle')"
			:tabs="$options.tabs"
			:current-tab="currentTab"
			@change="currentTab = $event"
			@close="$emit('close')"
		>
			<Component :is="currentTab.component" />
		</ZyroPopupCard>
	</BaseEditControls>
</template>

<script>
import BaseEditControls from '@/components/builder-controls/BaseEditControls.vue';
import i18n from '@/i18n/setup';

import EditMapTabAction from './EditMapTabAction.vue';
import EditMapTabLayout from './EditMapTabLayout.vue';

const tabs = [
	{
		title: i18n.t('common.general'),
		component: 'EditMapTabAction',
	},
	{
		title: i18n.t('common.layout'),
		component: 'EditMapTabLayout',
	},

];

export default {
	components: {
		BaseEditControls,
		EditMapTabAction,
		EditMapTabLayout,
	},
	data() {
		return { currentTab: this.$options.tabs[0] };
	},
	tabs,
};
</script>
